import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer>
      <div className="popular-searches">
        <p>Popular Searches: Wedding Photographers | Bridal Makeup | Wedding Venues | Wedding Cards</p>
      </div>
    </footer>
  );
};

export default Footer;
