import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Your Wedding, Your Way</h1>
        <p>Find the best wedding vendors with thousands of trusted reviews</p>
        <div className="search-bar">
          <select>
            <option>Select vendor type</option>
            <option>Photographer</option>
            <option>Makeup Artist</option>
            {/* Add more options */}
          </select>
          <select>
            <option>Select city</option>
            <option>Mumbai</option>
            <option>Delhi</option>
            {/* Add more cities */}
          </select>
          <button>Get Started</button>
        </div>
      </div>
    </section>
  );
};

export default Hero;
