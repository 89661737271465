import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="logo">
        <h1>TieTheDay</h1>
      </div>
      <nav>
        <ul className="nav-links">
          <li><a href="#">Location</a></li>
          <li><a href="#">Partners</a></li>
          <li><a href="#">Services</a></li>
          <li><a href="#">Event Planner</a></li>
          <li><a href="#">Wedding Shopping</a></li>
          <li><a href="#">Photoshoot</a></li>
          <li><a href="#">E-Invites</a></li>
        </ul>
      </nav>
      <div className="header-right">
        <button className="btn">Write a Review</button>
        {/* <button className="btn">Download App</button> */}
      </div>
    </header>
  );
};

export default Header;
