import React, { useEffect, useState } from 'react';

function RegisterPartner() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch categories from the API when the component mounts
  useEffect(() => {
    fetch('https://www.tietheday.com/getCategories.php')
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        console.log('Categories:', data);
        setCategories(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
        setLoading(false);
      });
  }, []);
  

  if (loading) {
    return <p>Loading categories...</p>;
  }

  return (
    <div className="register-partner-container">
      <h1>Partner Registration1</h1>
      <form className="register-partner-form">
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" placeholder="Enter your name" />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" placeholder="Enter your email" />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone:</label>
          <input type="text" id="phone" placeholder="Enter your phone number" />
        </div>

        <div className="form-group">
          <label htmlFor="category">Category:</label>
          <select id="category">
            <option value="">Select a category</option>
            {categories.map((category) => (
              <option key={category.id} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <button type="submit" className="submit-btn">Register</button>
      </form>
    </div>
  );
}

export default RegisterPartner;
