import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './Components/header';
import Hero from './Components/hero';
import Footer from './Components/footer';
import RegisterPartner from './Screens/RegisterPartner'; // Assuming this is the path

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        
        {/* Add a button to navigate to RegisterPartner at the top-right corner */}
        <nav className="top-right">
          <Link to="/register-partner">
            <button className="register-button">Register as Partner</button>
          </Link>
        </nav>

        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/register-partner" element={<RegisterPartner />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;
